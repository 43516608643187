
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DomSanitizer,SafeHtml,SafeResourceUrl } from "@angular/platform-browser";
import { map } from 'rxjs/operators';
import { CookieService } from '../cookie.service';
import { Template } from "../../models/template.models";
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  FB_API = `https://graph.facebook.com/${environment.fbVersion}`;
  apiUrl      = environment.apiUrl;
  frontUrl    = environment.frontUrl;
  fbId        = environment.fbAppId;

  constructor(private http: HttpClient, 
    @Inject(PLATFORM_ID) private platformId: any,
    private sanitizer: DomSanitizer,
      private cookieService: CookieService,
     private router: Router) { 
      this.apiUrl      = environment.apiUrl +`/${this.cookieService.getCurentLocale()}/api`;
     
     }



 /* API TODO LIST */

  get_todo(params: any){
    return this.http.get<any>(`${this.apiUrl}/todolist/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }



 /* API FOR FACEBOOK COMPANGIN */
  /* API FOR SEO MODULE */
  page_seo(params): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/seo/page_seo/`,{params});
  }

  updateSourceDeatils(pk, data): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/seo/${pk}/source-details/`,data);
  }
   
  updateSeoSiteDetails(pk, data): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/seo/${pk}/site-details/`,data);
  }
  updateRichSnippetsDetails(pk, data): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/seo/${pk}/rich-snippets-details/`,data);
  }
  seo_bot(params): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/seo/bot/`,{params});
  }

  /* API FOR FACEBOOK COMPANGIN */

  delete_media(pk,params: any){
    return this.http.delete<any>(`${this.apiUrl}/media/${pk}/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }


 
 
  get_ad_accounts(params): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/social_accounts/ad_accounts/`,{params});
  }
  get_ad_campaigns(params): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/adaccount/campaigns/`,{params});
  }
  import_ad_campaigns(params): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/adaccount/import_campaign/`,{params});
  }
  media_list(params: any){
    return this.http.get<any>(`${this.apiUrl}/media_file/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  create_media(data: any){
    return this.http.post<any>(`${this.apiUrl}/media_file/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  create_media_groups(data: any){
    return this.http.post<any>(`${this.apiUrl}/media_file/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  delete_media_file(pk,params: any){
    return this.http.delete<any>(`${this.apiUrl}/media_file/${pk}/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }

  ads_list(params: any){
    return this.http.get<any>(`${this.apiUrl}/design/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  create_ads(data: any){
    return this.http.post<any>(`${this.apiUrl}/design/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  create_draft_ads(data: any){
    return this.http.post<any>(`${this.apiUrl}/design/new_draft/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  update_ads(pk,data: any){
    return this.http.put<any>(`${this.apiUrl}/design/${pk}/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  auto_save(pk,data: any){
    return this.http.put<any>(`${this.apiUrl}/design/${pk}/auto_save/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  delete_ads(pk,params: any){
    return this.http.delete<any>(`${this.apiUrl}/design/${pk}/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  getInstagramAccounts(params){
    return this.http.get(`${this.apiUrl}/campaign/instagram_accounts/`,{params});
  }
  get_campaign(pk, params: any){
    return this.http.get<any>(`${this.apiUrl}/campaign/${pk}/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  create_campaign(data: any){
    return this.http.post<any>(`${this.apiUrl}/campaign/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  publish_campaign(pk, data: any){
    return this.http.put<any>(`${this.apiUrl}/campaign/${pk}/publish/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  remove_campaign(pk, params: any){
    return this.http.delete<any>(`${this.apiUrl}/campaign/${pk}/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  disable_campaign(pk, params: any){
    return this.http.post<any>(`${this.apiUrl}/campaign/${pk}/disable/`,params)
      .pipe(map((data:any) => {
          return data
      }));
  }
  update_campaign(pk,data: any){
    return this.http.put<any>(`${this.apiUrl}/campaign/${pk}/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  auto_save_campaign(pk,data: any){
    return this.http.put<any>(`${this.apiUrl}/campaign/${pk}/auto_save/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  copy_campaign(pk,data: any){
    return this.http.post<any>(`${this.apiUrl}/campaign/${pk}/copy/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  synchronous_campaign(params: any){
    return this.http.get<any>(`${this.apiUrl}/campaign/synchronous/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  list_campaign(params: any){
    return this.http.get<any>(`${this.apiUrl}/campaign/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  have_old(params: any){
    return this.http.get<any>(`${this.apiUrl}/campaign/have_old/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }

  new_draft_campaign(params: any){
    return this.http.post<any>(`${this.apiUrl}/campaign/new_draft/`,params)
      .pipe(map((data:any) => {
          return data
      }));
  }

  get_insights(pk, params: any){
    return this.http.get<any>(`${this.apiUrl}/campaign/${pk}/insights/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  get_insights_range(pk, params: any){
    return this.http.get<any>(`${this.apiUrl}/campaign/${pk}/insights_range/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  demographic_insights(pk, params: any){
    return this.http.get<any>(`${this.apiUrl}/campaign/${pk}/demographic_insights/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  device_platform_insights(pk, params: any){
    return this.http.get<any>(`${this.apiUrl}/campaign/${pk}/device_platform_insights/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }

  cost_action_insights(pk, params: any){
    return this.http.get<any>(`${this.apiUrl}/campaign/${pk}/cost_action_insights/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  /* API FOR GOOGLE ADS*/
  new_draft_campaign_google(params: any){
    return this.http.post<any>(`${this.apiUrl}/google-ads/campaign/draft/`,params)
      .pipe(map((data:any) => {
          return data
      }));
  }
  Campaign_setting(pk, params: any){
    return this.http.put<any>(`${this.apiUrl}/google-ads/campaign/${pk}/`,params)
      .pipe(map((data:any) => {
          return data
      }));  
  }
  Adfeed_settings(pk, params: any){
    return this.http.put<any>(`${this.apiUrl}/google-ads/campaign/${pk}/`,params)
      .pipe(map((data:any) => {
          return data
      }));  
  }
  Audience(pk, params: any){
    return this.http.put<any>(`${this.apiUrl}/google-ads/audience/${pk}/`,params)
    .pipe(map((data:any) => {
        return data
    }));
  }
  Budget_setting(pk, params: any){
    return this.http.put<any>(`${this.apiUrl}/google-ads/budget/${pk}/`,params)
      .pipe(map((data:any) => {
          return data
      }));
      
  }
  searchads(pk, params: any){
    return this.http.put<any>(`${this.apiUrl}/google-ads/ad/${pk}/`,params)
      .pipe(map((data:any) => {
          return data
      }));
      
  }
  assetgroupds(pk,site,params: any){
    return this.http.put<any>(`${this.apiUrl}/google-ads/asset_group/${pk}/?site=${site}`,params)
      .pipe(map((data:any) => {
          return data
      }));
      
  }
createassetgroup(site, campaign,data){
  return this.http.post<any>(`${this.apiUrl}/google-ads/asset_group/?site=${site}&campaign=${campaign}`,data)
  .pipe(map((data:any) => {
      return data
  }));
}
remove_assetgroup(pk,site){
  return this.http.delete<any>(`${this.apiUrl}/google-ads/asset_group/${pk}/?site=${site}`)
    .pipe(map((data:any) => {
        return data
    }));
}
  keywords(params: any){ 
    return this.http.post<any>(`${this.apiUrl}/google-ads/keyword/`,params)
      .pipe(map((data:any) => {
          return data
      }));
      
  }
  publishgoogleAds(params: any){ 
    return this.http.post<any>(`${this.apiUrl}/google-ads/publish/`,params)
      .pipe(map((data:any) => {
          return data
      }));
      
  }

  associateMarchantCenter(site, params: any){ 
    return this.http.post<any>(`${this.apiUrl}/merchant_centers/?site=${site}`,params)
      .pipe(map((data:any) => {
          return data
      }));
      
  }

  getlistfeed(site ,params: any){
    return this.http.get<any>(`${this.apiUrl}/merchant_centers/?site=${site}`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  get_campaign_setting(pk, params: any){
    return this.http.get<any>(`${this.apiUrl}/google-ads/campaign/${pk}/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  list_campaign_google(params: any){
    return this.http.get<any>(`${this.apiUrl}/google-ads/campaign/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  remove_campaign_google(pk, params: any){
    return this.http.delete<any>(`${this.apiUrl}/google-ads/campaign/${pk}/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }

  searchAudienceList(params) {
    return this.http.get<any>(`${this.apiUrl}/google-ads/target_suggestions/`,{params});
  }
  searchInteretList(params) {
    return this.http.get<any>(`${this.apiUrl}/google-ads/interests/`,{params});
  }
  searchLangage(params){
    return this.http.get<any>(`${this.apiUrl}/google-ads/languages/`,{params});
  }
  remove_keywords(pk, params: any){
    return this.http.delete<any>(`${this.apiUrl}/google-ads/keyword/${pk}/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  Getpageaccount(params){
    return this.http.get<any>(`${this.apiUrl}/google-ads/customers/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  convesionactions(site_id, customer_id){
    return this.http.get(`${this.apiUrl}/google-ads/conversion_actions/?site=${site_id}&customer_id=${customer_id}`)
      .pipe(map((data:any) => {
          return data
      }));
  }
  GetpageChild(params){
    return this.http.get<any>(`${this.apiUrl}/google-ads/childs/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  Sendcustomer(pk, params: any){
    return this.http.put<any>(`${this.apiUrl}/google-ads/campaign/${pk}/`,params)
      .pipe(map((data:any) => {
          return data
      }));
      
  }
  CheckConnexion(params){
    return this.http.get<any>(`${this.apiUrl}/google-ads/status/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  disable_Adscampaign(params: any){ 
    return this.http.post<any>(`${this.apiUrl}/google-ads/campaign/status/`,params)
      .pipe(map((data:any) => {
          return data
      }));
      
  }
 
  /* API FOR MANUAL COLLECTION */


  parse_order_from_csv( data: any) {
    return this.http.post<any>(`${this.apiUrl}/orders/parse-order-from-csv/`, data)
    .pipe(map(data => {
        return data
    }));
  }

  parse_order_from_xls( data: any) {
    return this.http.post<any>(`${this.apiUrl}/orders/parse-order-from-xls/`, data)
    .pipe(map(data => {
        return data
    }));
  }



  provider_list(params: any){
    return this.http.get<any>(`${this.apiUrl}/invitations_providers/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }

  invitation_provider_list(pk:any, params: any){
    return this.http.get<any>(`${this.apiUrl}/invitations_providers/${pk}/invitations/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }

  remove_invitation_provider(pk,params: any){
    return this.http.delete<any>(`${this.apiUrl}/invitations_providers/${pk}/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }

  mail_config(params){
    return this.http.get<any>(`${this.apiUrl}/mail_config/config/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }

  update_mail_config(pk, data){
    return this.http.put<any>(`${this.apiUrl}/mail_config/${pk}/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  activate_mail_config(pk, data){
    return this.http.put<any>(`${this.apiUrl}/mail_config/${pk}/active/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  

  test_mail_config(pk, data){
    return this.http.post<any>(`${this.apiUrl}/mail_config/${pk}/test/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  mail_reminder_config(params){
    return this.http.get<any>(`${this.apiUrl}/mail_reminder/config/`,{params})
      .pipe(map((data:any) => {
          return data
      }));
  }
  update_reminder_config(pk,data){
    return this.http.put<any>(`${this.apiUrl}/mail_reminder/${pk}/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }


  create_invitation( data: any) {
    return this.http.post<any>(`${this.apiUrl}/invitations_providers/`, data)
    .pipe(map(data => {
        return data
    }));
  }

  add_invitation( data: any) {
    return this.http.post<any>(`${this.apiUrl}/invitations/`, data)
    .pipe(map(data => {
        return data
    }));
  }


  downloadInvitationFile(id, site, filetype, filename):Observable<any>{
    
    return this.http.get(`${this.apiUrl}/invitations_providers/${id}/download/?site=${site}`, { responseType: 'blob' }).pipe(
      map(x => {
          var newBlob = new Blob([x], { type: filetype });
          // Deprecated
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //     window.navigator.msSaveOrOpenBlob(newBlob);
          //     return;
          // }
          const data = window.URL.createObjectURL(newBlob);
          var link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

          setTimeout(function () {
              window.URL.revokeObjectURL(data);
              link.remove();
          }, 100);
      }),
    );
     
  }

  getFormBadge(params){
    return this.http.get(`${this.apiUrl}/mail-request-form/config/`,{params});     
  } 
  updateFormBadge(pk, params){
    return this.http.put(`${this.apiUrl}/mail-request-form/${pk}/`,params);     
  } 
  getQrCodeImage(params){
    return this.http.get(`${this.apiUrl}/qr-code/image/`,{params});     
  }
  updateQrCodeImage(pk, params): Observable<any> {
    return this.http.put(`${this.apiUrl}/qr-code/${pk}/`,params);
  } 
  sendByMailQrCodeImage(pk, data){
    return this.http.post<any>(`${this.apiUrl}/qr-code/${pk}/send-by-email/`,data)
      .pipe(map((data:any) => {
          return data
      }));
  }

  orderList(params){
    return this.http.get<any>(`${this.apiUrl}/orders/`,{ params })
    .pipe(map(data => {
        return data
    }));
  }
  stopOrder(pk, data) {
    return this.http.put<any>(`${this.apiUrl}/orders/${pk}/stop-sending/`, data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  MarkAsShipped(pk, data) {
    return this.http.put<any>(`${this.apiUrl}/orders/${pk}/mark-as-shipped/`, data)
      .pipe(map((data:any) => {
          return data
      }));
  }
  sentAtOrder(pk, data) {
    return this.http.put<any>(`${this.apiUrl}/orders/${pk}/sent-at/`, data)
      .pipe(map((data:any) => {
          return data
      }));
  }

  getMessengerConfig(params){
    return this.http.get(`${this.apiUrl}/fb-messanger-config/config/`,{params});     
  }
  updateMessengerConfig(pk, data): Observable<any>{
    return this.http.put(`${this.apiUrl}/fb-messanger-config/${pk}/`,data)
  }



  /**
   * 
   * Payment api
   */
  
  checkoutPrice(data:any){
    return this.http.post<any>(`${this.apiUrl}/payment/`, data)
  }

  billinglist(params:any){
    return this.http.get<any>(`${this.apiUrl}/invoices/`, {params})
  }

  billingItem(pk:any, params){
    return this.http.get<any>(`${this.apiUrl}/invoices/${pk}/`, {params})
  }
  invoice_notPayed(params){
    return this.http.get<any>(`${this.apiUrl}/invoices/not-payed/`, {params})
  }
  create_stripe_checkout(pk:any, params): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/invoices/${pk}/create_stripe_checkout_Session/`,{params})
  }
  create_payment_stripe_checkout(params): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/payment/stripe-checkout-session/`,{params})
  }
  payment_paypal_checkout(params){
    return this.http.get<any>(`${this.apiUrl}/payment/paypal-checkout-session/`,{params})
  }
  getVatRates(params): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/payment/vat-rates/`, {params})
  }

  updatebillingItem(pk:any,data){
    return this.http.put<any>(`${this.apiUrl}/invoices/${pk}/`,data)
  }
  updatebillingInformation(pk:any,data){
    return this.http.put<any>(`${this.apiUrl}/paymentInformation/${pk}/`,data)
  }

  ContactSupport(data){
    return this.http.post<any>(`${this.apiUrl}/contact/`,data)
  }

  getWixCheckoutUrl(params){
    return this.http.get<any>(`${this.apiUrl}/payment/wix-checkout-url`,{ params })
  }
  getShopifyCheckoutUrl(params){
    return this.http.get<any>(`${this.apiUrl}/payment/shopify-checkout-url`,{ params })
  }

checkRecurringPlan(pk,site_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/company-plan/${pk}/check-recurring-charge/?site=${site_id}`);
  }





  /**
   * Api for social ads
   */

   get_ads_review(params){
    return this.http.get<any>(`${this.apiUrl}/reviews/ads_review/`, { params })
    .pipe(map(data => {
        return data
    }));
  }
  get_scoial_media_review(params){
    return this.http.get<any>(`${this.apiUrl}/reviews/social-media/`, { params })
    .pipe(map(data => {
        return data
    }));
  }
  update_scoial_media_config(data, pk){
    return this.http.put<any>(`${this.apiUrl}/social-media/${pk}/`, data)
    .pipe(map(data => {
        return data
    }));
  }
  get_scoial_media_config(params){
    return this.http.get<any>(`${this.apiUrl}/social-media/config/`, { params })
    .pipe(map(data => {
        return data
    }));
  }

  getSocialaccount(params): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/social_accounts/account/`,{params});
  }

  getEstimate(adAccount, params) {
    //return this.http.get(`${this.FB_API}/${adAccount}/reachestimate/`,{params});
    return this.http.get<any>(`${this.apiUrl}/audience/reach_estimate/`,{params});
  }
  deliveryEstimate(adAccount, params) {
    //return this.http.get(`${this.FB_API}/${adAccount}/reachestimate/`,{params});
    return this.http.get<any>(`${this.apiUrl}/audience/delivery_estimate/`,{params});
  }

  getInterest(params) {
    // return this.http.get<any>(`${this.FB_API}/search/`,{params});
    return this.http.get<any>(`${this.apiUrl}/audience/search/`,{params});
  }
  getGeoLocation(params) {
    // return this.http.get<any>(`${this.FB_API}/search/`,{params});
    return this.http.get<any>(`${this.apiUrl}/audience/search/`,{params});
  }
  searchAudience(params) {
    // return this.http.get<any>(`${this.FB_API}/search/`,{params});
    return this.http.get<any>(`${this.apiUrl}/audience/search/`,{params});
  }


  /**
   * 
   * @param urlshop 
   */
  connectToShopify(urlshop) {

    let redirect_uri = `${window.location.href}` 
    if (redirect_uri.includes('?')) { 
      history.pushState({}, null, redirect_uri.split('?')[0]); 
    }
    let href = `${urlshop}/admin/oauth/authorize?client_id=${environment.AppkeyShopify}&scope=${environment.ScopeShopify}&redirect_uri=${redirect_uri}&state={nonce}`

    window.location.href =  href; 
  }
   

  shopifyManualInstall(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/shopify/manual-install/`,data);
  }
  shopifyInstallBadge(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/shopify/install-badge/`,data);
  }
  shopifyInstallProduct(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/shopify/install-product/`,data);
  }
  shopifyInstallStarRatingProduct(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/shopify/install-star-rating-product/`,data);
  }
  shopifyInstallCarousel(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/shopify/install-carousel/`,data);
  }
  shopifyInstallCertiWidget(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/shopify/install-certi-widget/`,data);
  }
  shopifyCheckConfig(site_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/shopify/check-config/?site=${site_id}`);
  }
  getShopifyConfig(site_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/shopify/get-config/?site=${site_id}`);
  }
  checkEpagesConfig(site_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/epages/check-config/?site=${site_id}`);
  }
  checkWizishopConfig(site_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/wizishop/check-config/?site=${site_id}`);
  }
  logintoWizishop(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/wizishop/login/`,data);
  }
  signoutWizishop(id, site_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/wizishop/${id}/signout/?site=${site_id}`);
  }

  shopifyInstallPage(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/shopify/install-page/`,data);
  }
  shopifyFetchOrders(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/shopify/get-orders/`, data);
  }
  /*google ads*/
  googleCampaignAdsList(params): Observable<any>{
    return this.http.get<string>(`${this.apiUrl}/google-ads/campaign/`,{params});
  }
  googleAdsList(site_id): Observable<any>{
    return this.http.get<string>(`${this.apiUrl}/google-ads/customers/?site=${site_id}`);
  }
  googleAdsKeyWordList(site_id, data): Observable<any>{
    return this.http.post<string>(`${this.apiUrl}/google-ads/keyword/report/?site=${site_id}`, data);
  }
  googleAdsGender(site_id, data): Observable<any>{
    return this.http.post<string>(`${this.apiUrl}/google-ads/audience/report/gender/?site=${site_id}`, data);
  }
  googleAdsAge(site_id, data): Observable<any>{
    return this.http.post<string>(`${this.apiUrl}/google-ads/audience/report/age_ranges/?site=${site_id}`, data);
  }
  googleReport(site_id, data): Observable<any>{
    return this.http.post<string>(`${this.apiUrl}/google-ads/campaign/report/?site=${site_id}`, data);
  }
  googleAdsPerformance(site_id, data): Observable<any>{
    return this.http.post<string>(`${this.apiUrl}/google-ads/campaign/performance/?site=${site_id}`, data);
  }
  googleAdsDvice(site_id, data): Observable<any>{
    return this.http.post<string>(`${this.apiUrl}/google-ads/audience/report/devices/?site=${site_id}`, data);
  }
/*google shopping connect*/
/**
   * 
   * @param merchant_id 
   */
  connectMarchentID(urlshopping, merchant_id){
    let redirect_uri = `${window.location.href}` 
    if (redirect_uri.includes('?')) { 
      history.pushState({}, null, redirect_uri.split('?')[0]); 
    }
     window.location.href =  urlshopping; 
  }
  
  geturlgoogleshopping(params): Observable<string>{
    return this.http.get<string>(`${this.apiUrl}/google-shopping/url/`, {params});
  }

  configGoogleShopping(site_id): Observable<any>{
    return this.http.get<string>(`${this.apiUrl}/google-shopping/status/?site=${site_id}`);
  }
  googleShoppingProduct(site_id, data): Observable<any>{
    return this.http.post(`${this.apiUrl}/google-shopping/account/?site=${site_id}`, data);
  }
  googleShoppingProductList(site_id, data): Observable<any>{
    return this.http.post(`${this.apiUrl}/google-shopping/products/?site=${site_id}`, data);
  }
  googleShoppingReport(site_id, data): Observable<any>{
    return this.http.post(`${this.apiUrl}/google-shopping/report/?site=${site_id}`, data);
  }
  pintersetCatalog(site_id): Observable<any>{
    return this.http.get<string>(`${this.apiUrl}/pinterest/status/?site=${site_id}`);
  }
  activetPintersetCatalog(site_id, data): Observable<any>{
    return this.http.put<string>(`${this.apiUrl}/pinterest/activate/?site=${site_id}`,data);
  }

  facebookCatalog(site_id): Observable<any>{
    return this.http.get<string>(`${this.apiUrl}/facebook/status/?site=${site_id}`);
  }
  activetFacebookCatalog(site_id, data): Observable<any>{
    return this.http.put<string>(`${this.apiUrl}/facebook/activate/?site=${site_id}`,data);
  }

  productGroupList(params): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/product-group/`,{params});
  }
 
  allProductGroup(params): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/product-group/all/`,{params});
  }
  removeProductGroup(pk, params): Observable<any>{
    return this.http.delete<any>(`${this.apiUrl}/product-group/${pk}/`,{params});
  }

  groupsproducts(params): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/product-group/products/`,{params});
  }


  insertProductGroup(data):Observable<any> {
    return this.http.post(`${this.apiUrl}/product-group/`,data);
  }
  /** google shopping product list  */
  productList(params): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/product/list/`,{params});
  }

  assignProductGroup(pk,data): Observable<any>{
    return this.http.post<any>(`${this.apiUrl}/product/${pk}/assigner-to-group/`,data);
  }

  /** google shopping product list  */
  productDetails(params): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/product/details/`,{params});
  }
  remove_product(id,site_id){
    return this.http.delete<any>(`${this.apiUrl}/product/delete/?id=${id}&site=${site_id}`)
    .pipe(map(data => {
        return data
    }));
  }
  insertproduct(data,site_id):Observable<any> {
    return this.http.post(`${this.apiUrl}/product/insert/?site=${site_id}`,data);
  }

  updateproduct(data,site_id): Observable<any> {
    return this.http.put(`${this.apiUrl}/product/update_product/?site=${site_id}`,data);
  }

 
indexproduct(activate,site_id){
  return this.http.put<any>(`${this.apiUrl}/google-shopping/activate/?site=${site_id}`,activate)
  .pipe(map(data => {
      return data
  }));
}

indexproductreviews(reviews_active,site_id){
  return this.http.put<any>(`${this.apiUrl}/google-shopping/reviews_activate/?site=${site_id}`,reviews_active)
  .pipe(map(data => {
      return data
  }));
}


  InsereproductCsv(data,site_id): Observable<any> {
    return this.http.post(`${this.apiUrl}/product/upload/?site=${site_id}`,data);
  }
  checkFacebookIntegration(site_id,email): Observable<any>{
    return this.http.get<string>(`${this.apiUrl}/facebook-tab/status/?site=${site_id}&email=${email}`);
  }

  GetWixConfig(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/wix/get_instance/`,data);
  }
  WixSetting(site_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/wix/?site=${site_id}`);
  }
  WixSettingRemove(pk,site_id): Observable<any> {
    return this.http.delete(`${this.apiUrl}/wix/${pk}/?site=${site_id}`);
  }

  

  getBusinessManager(params, type="facebook"){

    return this.http.get<any>(`${this.apiUrl}/AdvertsAccount/auth_facebook_ads/`, { params })
    .pipe(map(data => {
        return data
    }));
  }


  getFacebookPage(params){
    let href = window.location.href
    params['redirect_fb_url'] = href
    if(href.indexOf('?code=') != -1){
      params['redirect_fb_url'] = href.slice(0,href.indexOf("?"))
    }
    return this.http.get<any>(`${this.apiUrl}/AdvertsAccount/auth_facebook_page/`, { params })
    .pipe(map(data => {
        return data
    }));
  }




  getPixelCode(adAccount, token) {
    return this.http.get(`${this.FB_API}/${adAccount}/adspixels/?access_token=${token}`);
  }

  get_top_review(params){
    return this.http.get<any>(`${this.apiUrl}/reviews/top_review/`, { params })
    .pipe(map(data => {
        return data
    }));
  }
  
    createCampaign(credentials) {
      return this.http.post(`${this.apiUrl}/Campaign/targeting/`, credentials);
    }
    getCampaign(credentials) {
      return this.http.post(`${this.apiUrl}/Campaign/get_compaign_ads/`, credentials);
    }
    removeCampaign(credentials){
      return this.http.post(`${this.apiUrl}/Campaign/remove_compaign_ads/`, credentials);
    }

    createFacebookAds(credentials){
      return this.http.post(`${this.apiUrl}/advert_facebook/`, credentials);
    }
    createInstagramAds(credentials){
      return this.http.post(`${this.apiUrl}/advert_instagram/`, credentials);
    }

    /**
     * setting api
     */

    getCompany(params){
      return this.http.get(`${this.apiUrl}/companies/`, {params});
    }
    updateCompany(pk,data){
      return this.http.put(`${this.apiUrl}/companies/${pk}/`,data);
    }
    getCategories(params){
      return this.http.get(`${this.apiUrl}/categories/`, {params});
    }

    getSite(pk, params){
      return this.http.get(`${this.apiUrl}/websites/${pk}/`,{params});
    }
    updateSite(pk,data){
      return this.http.put(`${this.apiUrl}/websites/${pk}/`,data);
    }
    uploadlogoSite(pk,data){
      return this.http.post(`${this.apiUrl}/websites/${pk}/upload_logo/`,data);
    }
    uploadcaptureSite(pk,data){
      return this.http.post(`${this.apiUrl}/websites/${pk}/upload_capture_site/`,data);
    }

    updateUserInfo(pk,data){
      return this.http.put(`${this.apiUrl}/profiles/${pk}/`,data);
    }
    updateUserPassword(data){
      return this.http.post(`${this.apiUrl}/profiles/change_password/`,data);
    }
    checkUserPassword(data){
      return this.http.post(`${this.apiUrl}/profiles/check_password/`,data);
    }

    getSocialpages(params): Observable<any> {
      return this.http.get<any>(`${this.apiUrl}/social_pages/`,{params});
    }

    getSocialpush(params): Observable<any> {
      return this.http.get<any>(`${this.apiUrl}/social_pages/social_push/`,{params});
    }

    createSocialpages(data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/social_pages/`,data);
    }
    createSocialpush(data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/social_pages/create_social_push/`,data);
    }

    deleteFacebeookSocialpush(pk,data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/facebook-rest-auth/${pk}/sign_out/`,data);
    }

    updateSocialpages(pk,data): Observable<any> {
      return this.http.put<any>(`${this.apiUrl}/social_pages/${pk}/`,data);
    }

    deleteSocialpages(pk, params): Observable<any> {
      return this.http.delete<any>(`${this.apiUrl}/social_pages/${pk}/`, {params});
    }

    FaceboookConnect(data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/facebook-rest-auth/connect/`,data);
    }
    notificationSetting(params): Observable<any> {
      return this.http.get<any>(`${this.apiUrl}/notification-settings/`,{params});
    }

    updateNotificationSetting(pk,data): Observable<any> {
      return this.http.put<any>(`${this.apiUrl}/notification-settings/${pk}/`,data);
    }

    getSocialaccounts(params): Observable<any> {
      return this.http.get<any>(`${this.apiUrl}/social_accounts/`,{params});
    }
    getGoogleScope(params): Observable<any> {
      return this.http.get<any>(`${this.apiUrl}/google-rest-auth/services/`,{params});
    }

    getGoogleAuthUrl(data,site): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/google-rest-auth/url/?site=${site}`,data);
    }

    deleteFacebeookSocial(pk,data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/facebook-rest-auth/${pk}/sign_out/`,data);
    }
    deleteGoogleSocial(pk,data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/google-rest-auth/${pk}/sign_out/`,data);
    }
    deleteTikTokSocial(pk,data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/tiktok-rest-auth/${pk}/sign_out/`,data);
    }

    GoogleConnect(data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/google-rest-auth/connect/`,data);
    }
    FacebookConnect(data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/facebook-rest-auth/connect_with_code/`,data);
    }
    tiktokConnect(data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/tiktok-rest-auth/connect/`,data);
    }
    getwebserviceconfig(site_id, params): Observable<any> {
      return this.http.get(`${this.apiUrl}/settings/status/?site=${site_id}`, {params});
    }
    updatewebserviceconfig(site_id,data): Observable<any> {
      return this.http.put(`${this.apiUrl}/settings/update_config/?site=${site_id}`,data);
    }

    getUsersData(site_id, params){
      return this.http.get(`${this.apiUrl}/access/users/`, {params});
    }
    inviteUser(data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/access/invite-user/`,data);
    }
    editUser(data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/access/edit-user/`,data);
    }
    deleteUser(data): Observable<any> {
      return this.http.post<any>(`${this.apiUrl}/access/remove-user/`,data);
    }
    /**
     * Modiration api
     */

    //getReview(site,limit,offset){
      //return this.http.get(`${this.apiUrl}/reviews?site=${site}&limit=${limit} &limit=${limit}&offset=${offset}`);
    downloadcsv(site):Observable<any>{
      return this.http.get(`${this.apiUrl}/review_export_csv/?site=${site}`, { responseType: 'blob' }).pipe(
        map(x => {
          var newBlob = new Blob([x], { type: "text/csv" });

            // Deprecated
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //     window.navigator.msSaveOrOpenBlob(newBlob);
            //     return;
            // }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);

            var link = document.createElement('a');
            link.href = data;
            link.download = "certishopping_review.csv";
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        }),
      );
       
    }
    getFacebookReview(site_id): Observable<any>{
      return this.http.get<string>(`${this.apiUrl}/facebook-review/status/?site=${site_id}`);
    }
    activateFacebookReview(activate,site_id){
      return this.http.put<any>(`${this.apiUrl}/facebook-review/activate/?site=${site_id}`,activate)
      .pipe(map(data => {
          return data
      }));
    }
    getGoogleReview(site_id): Observable<any>{
      return this.http.get<string>(`${this.apiUrl}/google-my-business/status/?site=${site_id}`);
    }
    getListLocation(site_id):Observable<any>{
      return this.http.get<string>(`${this.apiUrl}/google-my-business/locations/?site=${site_id}`);
    }
    activateGoogleReview(data){
      return this.http.put<any>(`${this.apiUrl}/google-my-business/activate/`,data)
      .pipe(map(data => {
          return data
      }));
    }
    importReviewGoogle(data){
      return this.http.put<any>(`${this.apiUrl}/google-my-business/locations/`,data)
      .pipe(map(data => {
          return data
      }));
    }

    getReview(params){
      return this.http.get(`${this.apiUrl}/reviews/`,{params});
    }

    generateReviewComment(pk, params){
      return this.http.get(`${this.apiUrl}/reviews/${pk}/auto-reply/`, {params});
    }
    publishReview(pk, data){
      return this.http.post(`${this.apiUrl}/reviews/${pk}/publish/`,data);
    }
    getRating(site){
      return this.http.get(`${this.apiUrl}/reviews/get_rating/?site=${site}`);
    }
    like(data, site){
      return this.http.post(`${this.apiUrl}/likes/?site=${site}`,data);
    }
    removelike(pk, params){
      return this.http.delete(`${this.apiUrl}/likes/${pk}/`, {params});
    }

    comment(data, site){
      return this.http.post(`${this.apiUrl}/comments/?site=${site}`,data);
    }

    updateComment(pk,data){
      return this.http.put(`${this.apiUrl}/comments/${pk}/`,data);
    }
    removeComment(pk, params){
      return this.http.delete(`${this.apiUrl}/comments/${pk}/`,{params});
    }
    abuse(data){
      return this.http.post(`${this.apiUrl}/abuse/`,data);
    }
    helpful(data){
      return this.http.post(`${this.apiUrl}/helpful/`,data);
    }
    add_image(pk,data){
      return this.http.post(`${this.apiUrl}/reviews/${pk}/set_image/`,data);
    }
    share_on_page(data): Observable<any> {
      return this.http.post(`${this.apiUrl}/reviews/share_review_fb/`,data);
    }
    post_on_page(data): Observable<any> {
      return this.http.post(`${this.apiUrl}/reviews/post_review_fb/`,data);
    }
    share_on_instagram(data): Observable<any> {
      return this.http.post(`${this.apiUrl}/reviews/share_review_instagram/`,data);
    }
    import_review( data: any) {
      return this.http.post<any>(`${this.apiUrl}/reviews/import_review/`, data)
      .pipe(map(data => {
          return data
      }));
    }

    parse_review_from_csv( data: any) {
      return this.http.post<any>(`${this.apiUrl}/reviews/parse_review_from_csv/`, data)
      .pipe(map(data => {
          return data
      }));
    }

    getUgc(params): Observable<any> {  
      return this.http.get(`${this.apiUrl}/ugc-media/`,{params});
    }
    approveMedia(pk, data): Observable<any> {  
      return this.http.put(`${this.apiUrl}/ugc-media/${pk}/approve/`,data);
    }
    hideMedia(pk, data): Observable<any> {  
      return this.http.put(`${this.apiUrl}/ugc-media/${pk}/hide/`,data);
    }

    shareOnTiktok(data): Observable<any> {  
      return this.http.post(`${this.apiUrl}/tiktok/upload/`,data);
    }
    autoReplyConfig(params): Observable<any>{
      return this.http.get(`${this.apiUrl}/auto-reply/config/`,{params})
    }
    saveReplyConfig(pk, data): Observable<any>{
      return this.http.put(`${this.apiUrl}/auto-reply/${pk}/`,data)
    }
    testReplyConfig(data): Observable<any>{
      return this.http.post(`${this.apiUrl}/auto-reply/test/`,data)
    }

    getClaims(params){
      return this.http.get(`${this.apiUrl}/orders-claims/`,{params});
    }
    getClaimsDetail(params){
      return this.http.get(`${this.apiUrl}/orders-claims/comments/`,{params});
    }  
    saveClaimReply(data){
      return this.http.post(`${this.apiUrl}/orders-claims/reply/`,data);
    }
    updateClaimStatus(data){
      return this.http.post(`${this.apiUrl}/orders-claims/status/`,data);
    }
    /**
     * Notification
     */

    notificationList(params): Observable<any> {
      return this.http.get(`${this.apiUrl}/notification/`,{params});
    }
    notificationUnread(pk, params): Observable<any> {
      return this.http.get(`${this.apiUrl}/notification/${pk}/unread/`, {params});
    }
    notificationUnreadAll(params): Observable<any> {
      return this.http.get(`${this.apiUrl}/notification/mark_all_as_read/`, {params});
    }



    /* widget*/

    carouselConfigTest(site_key, params): Observable<any> {
      return this.http.get(`${this.apiUrl}/widgets/carousel-config-test/${site_key}/`, {params});
    }
    updateCarouselConfig(site_key,data): Observable<any> {
      return this.http.post(`${this.apiUrl}/widgets/change-carousel-config/${site_key}/`, data);
    }

    GetBadgeConfig(params){
      return this.http.get(`${this.apiUrl}/badge_config/config/`,{params});     
    }  
    UpdateBadgeConfig(pk, params){
      return this.http.put(`${this.apiUrl}/badge_config/${pk}/`,params);     
    } 

    GetCarouselUgcConfig(params){
      return this.http.get(`${this.apiUrl}/carousel-ugc/config/`,{params});
    }
    UpdateCarouselUgcConfig(pk, params){
      return this.http.put(`${this.apiUrl}/carousel-ugc/${pk}/`,params);
    }
    starRating(params): Observable<any> {
      return this.http.get(`${this.apiUrl}/star_rating/config/`, {params});
    }
    updateStarRatings(pk, params): Observable<any> {
      return this.http.put(`${this.apiUrl}/star_rating/${pk}/`,params);
    }
    GetProductwidgetConfig(params){
      return this.http.get(`${this.apiUrl}/product_review_config/config/`,{params});     
    }       
    UpdateProductwidgetConfig(pk, params){
      return this.http.put(`${this.apiUrl}/product_review_config/${pk}/`,params);     
    } 
    GetCertiWidgetConfig(params){
      return this.http.get(`${this.apiUrl}/certi_badge_config/config/`,{params});  
    }
    getBadgeCertificateConfig(params){
      return this.http.get(`${this.apiUrl}/certi-badge-certification/check-config/`,{params});  
    }
    updatetBadgeCertificateConfig(pk, params): Observable<any> {
      return this.http.put(`${this.apiUrl}/certi-badge-certification/${pk}/`,params);
    } 
    UpdateCertiWidgetConfig(pk, params): Observable<any> {
      return this.http.put(`${this.apiUrl}/certi_badge_config/${pk}/`,params);
    } 
    updateTranslateConfig(pk, params): Observable<any> {
      return this.http.put(`${this.apiUrl}/translate/config/?site=${pk}`,params);
    }
    getTranslateConfig(pk){
      return this.http.get(`${this.apiUrl}/translate/preferences/?site=${pk}&type=LANGUAGE`);  
    }

    getReviewsPageConfig(params){
      return this.http.get(`${this.apiUrl}/reviews-page/config/`,{params});     
    }  

    updateReviewsPageConfig(pk, params){
      return this.http.put(`${this.apiUrl}/reviews-page/${pk}/`,params);     
    } 

}
